
import {defineComponent, ref} from 'vue';
import CreateModal from '@/components/pitches/CreateModal.vue';
import NavMenuItem from '@/components/common/NavMenuItem.vue';
import {PitchData} from '@/models/Pitch';
import PitchMoment from '@/models/PitchMoment';
import PitchesFilter from '@/components/pitches/Filters.vue';
import TeamsPitchMomentsCollapse from '@/components/teams/PitchMomentsCollapse.vue';
import pitchesStore from '@/store/pitches/index';
import {showModal} from '@/library/helpers';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        CreateModal,
        NavMenuItem,
        PitchesFilter,
        TeamsPitchMomentsCollapse,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const {currentTeam, teamProgram} = teamStore;

        return {
            createModal: ref<typeof CreateModal>(),
            currentTeam,
            modalName: 'pitch-create-modal',
            pitchAttributes: ref<Partial<PitchData>>({}),
            pitches: pitchesStore.pitches,
            teamProgram,
        };
    },
    computed: {
        pitchesCollapseActive(): boolean {
            return ['pitchMoments.index'].includes(this.$route.name as string);
        },
    },
    mounted() {
        if (this.createModal) {
            /*
             * The element has to be appended to the body, because the
             * z-index of the menu interferes with the z-index of the modal.
             */
            document.body.appendChild(this.createModal.$el);
        }
    },
    beforeUnmount() {
        if (this.createModal) {
            /*
             * The element has to be appended back to the component, because
             * otherwise it will never be unmounted.
             */
            this.$el.appendChild(this.createModal.$el);
        }
    },
    methods: {
        async goToPitch(pitchMoment: PitchMoment) {
            const firstPitch = pitchMoment.pitches?.first();

            if (firstPitch && firstPitch.slug) {
                await this.$router.push(firstPitch.getLocation());

                return;
            }

            if (!this.currentTeam) {
                return;
            }

            this.pitchAttributes = {
                pitchMoment,
                template: pitchMoment.template,
                name: `${pitchMoment.name} ${this.currentTeam.name}`,
            };

            /*
             * The create modal is remounted when the attributes change, so we have
             * to wait until it has finished mounting before the modal can be shown.
             */
            await this.$nextTick();

            showModal(this.modalName);
        },
        goToPitchMoments() {
            this.$router.push({name: 'pitchMoments.index'});
        },
    },
});
