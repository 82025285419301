import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenuItem = _resolveComponent("NavMenuItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedFilters, (filterOption) => {
      return (_openBlock(), _createElementBlock("li", {
        key: filterOption.filter || filterOption.sort,
        class: "d-grid"
      }, [
        _createVNode(_component_NavMenuItem, {
          class: _normalizeClass(["d-flex align-items-center mb-4 py-4", {'active text-primary fw-bolder': _ctx.isActive(filterOption)}]),
          collapsed: _ctx.collapsed,
          "data-test-id": `filter-${filterOption.filter || filterOption.sort}`,
          icon: filterOption.icon,
          title: filterOption.label,
          onItemClicked: ($event: any) => (_ctx.filterPitches(filterOption))
        }, null, 8, ["class", "collapsed", "data-test-id", "icon", "title", "onItemClicked"])
      ]))
    }), 128))
  ]))
}