
import {defineComponent, ref} from 'vue';
import NavMenuItem from '@/components/common/NavMenuItem.vue';
import isEqual from 'lodash/isEqual';
import store from '@/store/pitches/index';
import {t} from '@/vendor/I18n';
import teamStore from '@/store/userTeams';

/*
 * Defines a filter option to filter the pitches collection on. It can either be
 * a filter or sort, but either option needs to be set.
 * If no value is set, it will default to 'true'.
 * Icon and label are shown to the user.
 */
interface FilterOption {
    filter?: string;
    icon: string;
    label: string;
    requiresTeam?: boolean;
    sort?: string;
    value?: string|number|boolean;
}

export default defineComponent({
    components: {
        NavMenuItem,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const filters: FilterOption[] = [
            {
                icon: 'file',
                label: t('pitch.filters.pitches'),
                sort: '-recent',
            },
            /*
             * Filter options have been disabled for now based on #PIFRO-372.
             * {
             *     icon: 'clock',
             *     label: t('pitch.filters.recent'),
             *     sort: '-recent',
             * },
             * {
             *     filter: 'drafts',
             *     icon: 'file',
             *     label: t('pitch.filters.draft'),
             *     requiresTeam: true,
             * },
             * {
             *     filter: 'sharedWith',
             *     icon: 'upload',
             *     label: t('pitch.filters.shared'),
             *     value: 'me',
             * },
             */
        ];

        return {
            activeFilter: ref<FilterOption>(filters[0]),
            filters,
            pitches: store.pitches,
            teamsIsEmpty: teamStore.teamsIsEmpty,
        };
    },
    computed: {
        allowedFilters(): FilterOption[] {
            return this.teamsIsEmpty
                ? this.filters.filter((filter: FilterOption) => !filter.requiresTeam)
                : this.filters;
        },
        filterOptions(): FilterOption[] {
            return this.allowedFilters.filter((option: FilterOption) => option.filter != null);
        },
    },
    created() {
        this.addSharedWithFilter();
    },
    methods: {
        addSharedWithFilter() {
            /*
             * If the user is not in a team, always get the pitches that are
             * shared with the user.
             */
            if (this.teamsIsEmpty) {
                this.pitches.addFilter('sharedWith', 'me');
            }
        },
        async filterPitches(filterOption: FilterOption) {
            this.removeFilters();

            if (this.$route.name !== 'home') {
                await this.$router.push({name: 'home'});
            }

            /**
             * Sort can be set or undefined, this way a filterOption can have
             * both a sort and a filter.
             */
            this.pitches.sorts = filterOption.sort;

            if (filterOption.filter) {
                this.pitches.addFilter(filterOption.filter, filterOption.value || true);
            }

            this.addSharedWithFilter();

            this.activeFilter = filterOption;
        },
        removeFilters() {
            this.filterOptions.forEach((filterOption: FilterOption) => {
                this.pitches.removeFilter(filterOption.filter!);
            });
        },
        isActive(filterOption: FilterOption): boolean {
            if (this.$route.name !== 'home') {
                return false;
            }

            return isEqual(filterOption, this.activeFilter);
        },
    },
});
