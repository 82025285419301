
import NavMenuItem from '@/components/common/NavMenuItem.vue';
import PitchMoment from '@/models/PitchMoment';
import Team from '@/models/Team';
import TeamType from '@/library/enumerations/TeamType';
import TeamsCollapse from '@/components/organisations/TeamsCollapse.vue';
import TeamsPitchMomentsCollapse from '@/components/teams/PitchMomentsCollapse.vue';
import {defineComponent} from 'vue';
import userTeams from '@/store/userTeams';

export default defineComponent({
    components: {
        NavMenuItem,
        TeamsCollapse,
        TeamsPitchMomentsCollapse,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            TeamType,
            teamProgram: userTeams.teamProgram,
        };
    },
    computed: {
        pitchesCollapseActive(): boolean {
            return ['home', 'pitchMoments.show'].includes(this.$route.name as string);
        },
        teamsCollapseActive(): boolean {
            return ['teams.index', 'teams.edit'].includes(this.$route.name as string);
        },
        programsCollapseActive(): boolean {
            return ['programs.index', 'pitchMoments.edit'].includes(this.$route.name as string);
        },
        academyActive(): boolean {
            return ['academy'].includes(this.$route.name as string);
        },
    },
    methods: {
        goToPitchMoments() {
            this.$router.push({name: 'home'});
        },
        goToPitchMomentDetail(pitchMoment: PitchMoment) {
            this.$router.push(pitchMoment.getLocation('show'));
        },
        goToTeamIndex(type: TeamType) {
            const team = new Team({type});

            this.$router.push(team.getLocation('index'));
        },
        goToPitchMomentEdit(pitchMoment: PitchMoment) {
            this.$router.push(pitchMoment.getLocation());
        },
    },
});
