import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e61fc6c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex container-fluid g-0 min-vh-100 bg-light" }
const _hoisted_2 = { class: "d-flex flex-column w-100" }
const _hoisted_3 = { class: "h-100 w-100 position-relative" }
const _hoisted_4 = { class: "navbar-menu-collapse d-flex justify-content-center align-items-center position-absolute h-100 top-0 end-0 me-n1" }
const _hoisted_5 = { class: "flex-grow-1 pt-6 px-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_NavbarMenu = _resolveComponent("NavbarMenu")!
  const _component_NavbarTop = _resolveComponent("NavbarTop")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NavbarMenu, {
          class: _normalizeClass(["navbar-menu position-absolute transition border-end px-1 bg-white inset-0", {'navbar-menu-collapsed': _ctx.navbarMenuCollapsed}]),
          collapsed: _ctx.navbarMenuCollapsed,
          "team-type": _ctx.teamTypeNumber
        }, {
          "menu-append": _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn btn-white btn-circle btn-xs no-focus border bg-active-solid",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args)))
              }, [
                _createVNode(_component_Fa, {
                  class: _normalizeClass(["text-muted transition", {'fa-rotate-180': _ctx.navbarMenuCollapsed}]),
                  icon: "chevron-left",
                  size: "sm"
                }, null, 8, ["class"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["class", "collapsed", "team-type"]),
        _createElementVNode("div", {
          class: _normalizeClass(["h-100 d-flex flex-column flex-grow-1 transition", [_ctx.navbarMenuCollapsed ? 'navbar-menu-collapsed-offset' : 'navbar-menu-offset']])
        }, [
          _createVNode(_component_NavbarTop, {
            class: _normalizeClass(["align-items-stretch bg-light pt-6 px-12", [_ctx.stickyNavbar ? 'sticky-top' : 'pb-6', {'border-bottom': _ctx.navbarBorder}]])
          }, null, 8, ["class"]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_RouterView, {
              key: _ctx.$route.path,
              class: "h-100",
              "team-type": _ctx.teamTypeNumber
            }, null, 8, ["team-type"]))
          ])
        ], 2)
      ])
    ])
  ]))
}