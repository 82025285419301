import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label"
}
const _hoisted_2 = { class: "dropdown-item text-center pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FormSelect = _resolveComponent("FormSelect")!

  return (_openBlock(), _createBlock(_component_FormSelect, {
    ref: "formSelect",
    "infinite-scroll-callback": _ctx.infiniteScrollCallback,
    "model-value": _ctx.value,
    options: _ctx.options,
    onDropdownToggled: _ctx.fetchCollection,
    "onUpdate:modelValue": _ctx.emitOption
  }, _createSlots({
    label: _withCtx(({label}) => [
      _renderSlot(_ctx.$slots, "label", { label: label }, () => [
        label
          ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(label), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 2
  }, [
    (_ctx.collection.loading)
      ? {
          name: "dropdown-no-results",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Spinner)
            ])
          ])
        }
      : undefined
  ]), 1032, ["infinite-scroll-callback", "model-value", "options", "onDropdownToggled", "onUpdate:modelValue"]))
}