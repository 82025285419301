
import {defineComponent, ref} from 'vue';
import Collapse from '@/components/common/Collapse.vue';
import NavMenuItem from '@/components/common/NavMenuItem.vue';
import {Teams} from '@/models/Team';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        Collapse,
        NavMenuItem,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const {teamProgram} = teamStore;

        return {
            teamProgram,
            teamsCollapsed: ref<boolean>(true),
            teams: ref<Teams>(new Teams()),
        };
    },
    methods: {
        fetchTeams() {
            this.teamsCollapsed = !this.teamsCollapsed;

            if (this.teamsCollapsed) {
                return;
            }

            if (
                this.teams.isEmpty()
                && !this.teams.loading
            ) {
                this.teams.fetch({url: `/teams/${this.teamProgram.id}/teams`});
            }
        },
    },
});
