import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "list-unstyled mb-0" }
const _hoisted_2 = {
  key: 0,
  class: "d-grid"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "ms-4" }
const _hoisted_5 = {
  key: 1,
  class: "px-4 py-3 mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_NavMenuItem = _resolveComponent("NavMenuItem")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_Collapse, _mergeProps({
        class: "d-grid",
        name: `program-pitch-moments-${_ctx.icon}`
      }, _ctx.$attrs), {
        "collapse-trigger": _withCtx(({target}) => [
          _renderSlot(_ctx.$slots, "default", {
            icon: _ctx.icon,
            onClick: _ctx.fetchPitchMoments,
            target: target,
            title: _ctx.collapseTitle
          }, () => [
            _createVNode(_component_NavMenuItem, {
              class: "collapse-trigger d-flex align-items-center collapsed mb-4 py-4",
              collapsed: _ctx.collapsed,
              "data-bs-target": target,
              "data-bs-toggle": "collapse",
              icon: _ctx.icon,
              title: _ctx.collapseTitle,
              onItemClicked: _ctx.fetchPitchMoments
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_Fa, {
                  class: "collapse-trigger-arrow",
                  icon: "chevron-down"
                }, null, 512), [
                  [_vShow, !_ctx.collapsed]
                ])
              ]),
              _: 2
            }, 1032, ["collapsed", "data-bs-target", "icon", "title", "onItemClicked"])
          ])
        ]),
        default: _withCtx(() => [
          (!_ctx.collapsed)
            ? (_openBlock(), _createBlock(_component_WaitForResource, {
                key: 0,
                resource: _ctx.pitchMoments
              }, {
                default: _withCtx(() => [
                  (_ctx.pitchMoments.count())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pitchMoments, (pitchMoment, index) => {
                          return (_openBlock(), _createElementBlock("button", {
                            key: `${_ctx.collapseTitle}-${pitchMoment.id}`,
                            class: "btn btn-white fw-normal text-muted text-start text-truncate mb-2",
                            onClick: ($event: any) => (_ctx.$emit('pitch-moment-clicked', pitchMoment))
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.padStart((index + 1).toString(), 2, '0')) + " ", 1),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(pitchMoment.name), 1)
                          ], 8, _hoisted_3))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('words.noResults')), 1))
                ]),
                _: 1
              }, 8, ["resource"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 16, ["name"])
    ])
  ]))
}