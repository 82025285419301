
import {PropType, defineComponent} from 'vue';
import Notification from '@/models/Notification';
import NotificationComponent from '@/components/notifications/Notification.vue';
import {Notifications} from '@/models/Notification';

export default defineComponent({
    components: {
        NotificationComponent,
    },
    props: {
        notifications: {
            type: Object as PropType<Notifications>,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        selectNotification(notification: Notification): void {
            this.$router.push(notification.getModelLocation());
        },
    },
});
