
import Notification, {Notifications} from '@/models/Notification';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import NotificationsGroup from '@/components/notifications/NotificationsGroup.vue';
import auth from '@/store/auth';
import {defineComponent} from 'vue';
import {isToday} from 'date-fns';

export default defineComponent({
    components: {
        Dropdown,
        NotificationsGroup,
    },
    setup() {
        return {
            user: auth.me,
        };
    },
    computed: {
        unreadNotificationsCount(): number|string {
            const unreadCount = Math.max(
                this.user!.unreadNotifications! - this.user!.notifications!.unread.count(),
                0,
            );

            if (unreadCount > 99) {
                return '99+';
            }

            return unreadCount;
        },
        todayNotifications(): Notifications {
            return this.user!.notifications!.filter((notification: Notification) => isToday(new Date(notification.createdAt!)));
        },
        olderNotifications(): Notifications {
            return this.user!.notifications!.diff(this.todayNotifications);
        },
        notificationOptions(): DropdownOption[] {
            return this.user!.notifications!.all().map((notification: Notification) => ({
                notification,
                label: '',
                key: notification.id!,
            }));
        },
    },
    created() {
        if (!this.user?.notifications) {
            return;
        }

        this.user.notifications.setSort('-createdAt');
    },
    methods: {
        fetchInitialNotifications() {
            if (
                !this.user!.notifications!.isEmpty()
                || this.user!.notifications!.loading
            ) {
                return;
            }

            this.fetchNotifications();
        },
        fetchNotifications(): void {
            this.user!.notifications!.fetch({
                url: `/notifications/users/${this.user!.id}`,
            });
        },
        markAllAsRead() {
            this.user!.markAllNotificationsRead();
        },
    },
});
