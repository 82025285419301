
import {PropType, defineComponent, ref} from 'vue';
import Collection from '@/models/Collection';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import FormSelect from '@/components/common/form/FormSelect.vue';
import {fetchCollectionUntilScrollable} from '@/library/helpers';
import get from 'lodash/get';

/**
 * Creates a wrapper around the select component for a collection.
 * Emits 'update:modelValue' when the user has selected a different option to
 * update the value.
 * By default, the collection is only fetched when the dropdown is opened but if
 * fetchOnMounted is enabled, it will fetch when the component is mounted.
 */
export default defineComponent({
    components: {
        FormSelect,
    },
    props: {
        collection: {
            type: Object as PropType<Collection<Record<string, any>, Record<string, any>>>,
            required: true,
        },
        fetchOnMounted: {
            type: Boolean,
            default: false,
        },
        keyProp: {
            type: String,
            default: 'id',
        },
        labelProp: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object as PropType<Record<string, any>>,
            default: null,
        },
    },
    emits: [
        'update:modelValue',
    ],
    setup() {
        return {
            formSelect: ref(),
        };
    },
    computed: {
        value(): string | number {
            return get(this.modelValue, this.keyProp);
        },
        options(): DropdownOption[] {
            if (!this.collection.count() && !this.modelValue) {
                return [];
            }

            if (!this.collection.count()) {
                return [{
                    key: get(this.modelValue, this.keyProp),
                    label: get(this.modelValue, this.labelProp),
                }];
            }

            return this.collection
                .all()
                .map((item: any) => ({
                    key: get(item, this.keyProp),
                    label: get(item, this.labelProp),
                }));
        },
        selectedOption(): DropdownOption | undefined {
            if (!this.modelValue) {
                return undefined;
            }

            return this.options.find((option: DropdownOption) => option.key === this.modelValue[this.keyProp]);
        },
    },
    watch: {
        'collection.endpoint': function handler() {
            this.collection.clear();
        },
    },
    mounted() {
        if (this.fetchOnMounted) {
            this.fetchCollection();
        }
    },
    methods: {
        emitOption(optionKey: string | number) {
            const selectedItem = this.collection.firstWhere(this.keyProp, optionKey);

            this.$emit('update:modelValue', selectedItem);
        },
        fetchCollection() {
            /*
             * If the collection is empty, we fetch the collection. If the
             * collection is not empty, we can assume the endpoint hasn't changed
             * so the models don't need to be re-fetched.
             */
            if (
                this.collection.isEmpty()
                && !this.collection.loading
                && this.formSelect
            ) {
                fetchCollectionUntilScrollable(
                    this.collection,
                    this.formSelect.$el.querySelector('.dropdown-menu')!,
                );
            }
        },
        infiniteScrollCallback() {
            if (!this.collection.lastPageFetched()) {
                this.collection.fetch();
            }
        },
    },
});
