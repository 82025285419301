import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ms-4 text-truncate"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-white fw-normal text-start overflow-x-hidden",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createVNode(_component_Fa, {
      class: _normalizeClass({'mx-auto': _ctx.collapsed}),
      icon: _ctx.icon
    }, null, 8, ["class", "icon"]),
    (!_ctx.collapsed)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}