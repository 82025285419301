
import {PropType, defineComponent} from 'vue';
import {RouteLocationRaw} from 'vue-router';

/**
 * Creates a collapsable menu item for the navigation menu.
 * By default, it shows an icon and title with an optional default slot to
 * append after the title.
 * In collapsed mode it displays only the icon and optional default slot.
 */
export default defineComponent({
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        to: {
            type: Object as PropType<RouteLocationRaw>,
            default: null,
        },
    },
    emits: [
        'item-clicked',
    ],
    methods: {
        handleClick() {
            if (this.to) {
                this.$router.push(this.to);
            }

            this.$emit('item-clicked');
        },
    },
});
