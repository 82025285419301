
import {Component, defineComponent} from 'vue';
import OrganisationsNavbarMenu from '@/components/organisations/NavbarMenu.vue';
import PitchyLogo from '@/components/common/PitchyLogo.vue';
import TeamType from '@/library/enumerations/TeamType';
import TeamsNavbarMenu from '@/components/teams/NavbarMenu.vue';
import authStore from '@/store/auth';
import teamStore from '@/store/userTeams';

export default defineComponent({
    name: 'NavbarMenu',
    components: {
        OrganisationsNavbarMenu,
        PitchyLogo,
        TeamsNavbarMenu,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        teamType: {
            type: Number,
            required: true,
            validator: (value: number) => TeamType.values.includes(value),
        },
    },
    setup() {
        const {me} = authStore;
        const {currentTeam} = teamStore;

        return {
            me,
            currentTeam,
        };
    },
    computed: {
        navbarComponent(): Component {
            switch (this.teamType) {
                case TeamType.ORGANISATION:
                    return OrganisationsNavbarMenu;
                default:
                    return TeamsNavbarMenu;
            }
        },
    },
    methods: {
        goToProfileTeam() {
            this.$router.push({
                name: 'users.edit',
                query: {
                    view: 'team',
                },
            });
        },
    },
});
