import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31a4355b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-sticky vh-100 top-0 whitespace-nowrap d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-items-center py-6 mx-6 border-bottom overflow-x-hidden"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "small ms-4" }
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = { class: "flex-grow-0 position-relative collapse-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PitchyLogo = _resolveComponent("PitchyLogo")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.currentTeam)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "square-md rounded cursor-pointer",
              "data-test-id": "img-team-logo",
              src: _ctx.currentTeam.logo?.thumbXs || require('@/assets/images/pitchy.png'),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToProfileTeam && _ctx.goToProfileTeam(...args)))
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.currentTeam.name) + " ", 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.me?.fullName), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["overflow-x-hidden scrollbar flex-grow-1 mt-6", {'mx-3': _ctx.collapsed}])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.navbarComponent), { collapsed: _ctx.collapsed }, null, 8, ["collapsed"]))
      ], 2),
      _createElementVNode("div", _hoisted_6, [
        (!_ctx.collapsed)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: {name: 'home'}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PitchyLogo, { class: "w-25 my-10 ms-6 text-primary" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "menu-append", {}, undefined, true)
      ])
    ])
  ]))
}