import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute translate-middle badge rounded-pill bg-danger"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "dropdown-item text-center pointer-events-none"
}
const _hoisted_4 = {
  key: 1,
  class: "dropdown-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_NotificationsGroup = _resolveComponent("NotificationsGroup")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.user?.notifications)
    ? (_openBlock(), _createBlock(_component_Dropdown, {
        key: 0,
        align: "end",
        "full-width": "",
        "item-class": "p-6",
        "menu-class": "mt-4 min-w-400px max-h-75vh overflow-auto shadow-sm scrollbar",
        options: _ctx.notificationOptions
      }, {
        "dropdown-toggle": _withCtx(() => [
          _createElementVNode("button", {
            class: "btn btn-outline-light btn-circle btn-md position-relative",
            "data-bs-toggle": "dropdown",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fetchInitialNotifications && _ctx.fetchInitialNotifications(...args)))
          }, [
            _createVNode(_component_Fa, {
              class: "cursor-pointer",
              icon: "notification",
              size: "lg"
            }),
            (_ctx.unreadNotificationsCount)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.unreadNotificationsCount), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        "options-prepend": _withCtx(() => [
          _createElementVNode("div", {
            class: "d-flex justify-content-between p-6 position-sticky top-0 bg-white shadow-sm border-bottom z-index-1",
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('notifications.title')), 1),
            (_ctx.user.unreadNotifications)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "text-decoration-none",
                  "data-test-id": "btn-mark-read",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.markAllAsRead && _ctx.markAllAsRead(...args)))
                }, _toDisplayString(_ctx.$t('notifications.actions.markAllRead')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        options: _withCtx(() => [
          (_ctx.todayNotifications.count())
            ? (_openBlock(), _createBlock(_component_NotificationsGroup, {
                key: 0,
                notifications: _ctx.todayNotifications,
                title: _ctx.$t('words.today'),
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["notifications", "title"]))
            : _createCommentVNode("", true),
          (_ctx.olderNotifications.count())
            ? (_openBlock(), _createBlock(_component_NotificationsGroup, {
                key: 1,
                notifications: _ctx.olderNotifications,
                title: _ctx.$t('words.older'),
                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["notifications", "title"]))
            : _createCommentVNode("", true)
        ]),
        "options-append": _withCtx(() => [
          (!_ctx.user?.notifications?.lastPageFetched())
            ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                _createElementVNode("button", {
                  class: "btn dropdown-item py-2 w-100 text-center",
                  type: "button",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fetchNotifications && _ctx.fetchNotifications(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t('words.showMore')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        "dropdown-no-results": _withCtx(() => [
          (_ctx.user?.notifications?.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Spinner)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('notifications.noResults')), 1))
        ]),
        _: 1
      }, 8, ["options"]))
    : _createCommentVNode("", true)
}