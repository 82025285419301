
import DropdownMenu, {DropdownOption} from '@/components/common/DropdownMenu.vue';
import {PropType, defineComponent} from 'vue';
import Dropdown from '@/components/common/Dropdown.vue';
import Error from '@/components/common/Error.vue';
import Errors from '@/library/model-collection/Errors';

/**
 * Creates a select component for options.
 * Makes use of the common dropdown component to show the options.
 * Will show the selected value as the title of the dropdown toggle.
 * Emits 'dropdown-toggled' when the user toggles the dropdown menu.
 * Emits 'update:modelValue' when the user has selected a different option to
 * update the value.
 */
export default defineComponent({
    components: {
        Dropdown,
        DropdownMenu,
        Error,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        errorKey: {
            type: [String, Array],
            default: null,
        },
        errors: {
            type: Object as PropType<Errors>,
            default: () => ({}),
        },
        infiniteScrollCallback: {
            type: Function,
            default: () => true,
        },
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        options: {
            type: Array as PropType<DropdownOption[]>,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'dropdown-toggled',
        'update:modelValue',
    ],
    computed: {
        selectedOption(): DropdownOption | undefined {
            if (!this.modelValue) {
                return undefined;
            }

            return this.options.find((option: DropdownOption) => option.key === this.modelValue);
        },
        dropdownValue(): string {
            /*
             * \u200b is the unicode for a zero width space which is needed to
             * display the button with proper height if the text is empty.
             */
            return this.selectedOption?.label
                || this.placeholder
                || '\u200b';
        },
    },
});
