
import {defineComponent, ref} from 'vue';
import Collapse from '@/components/common/Collapse.vue';
import NavMenuItem from '@/components/common/NavMenuItem.vue';
import {PitchMoments} from '@/models/PitchMoment';
import padStart from 'lodash/padStart';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        Collapse,
        NavMenuItem,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'calendar',
        },
        title: {
            type: String,
            default: null,
        },
    },
    emits: [
        'pitch-moment-clicked',
    ],
    setup() {
        const {teamProgram} = teamStore;

        return {
            teamProgram,
            pitchMomentsCollapsed: ref<boolean>(true),
            pitchMoments: ref<PitchMoments>(new PitchMoments([], {
                include: ['pitches'],
                pitchTeam: teamStore.currentTeam.value?.id,
                sorts: 'presentationAt',
            })),
        };
    },
    computed: {
        collapseTitle(): string {
            return this.title || this.teamProgram.name;
        },
    },
    methods: {
        padStart,
        fetchPitchMoments() {
            this.pitchMomentsCollapsed = !this.pitchMomentsCollapsed;

            if (this.pitchMomentsCollapsed) {
                return;
            }

            if (
                this.pitchMoments.isEmpty()
                && !this.pitchMoments.loading
            ) {
                this.pitchMoments.fetch({url: `/teams/${this.teamProgram.id}/pitch-moments`});
            }
        },
    },
});
