
import {PropType, defineComponent} from 'vue';
import Notification from '@/models/Notification';
import ProfilePicture from '@/components/users/ProfilePicture.vue';

export default defineComponent({
    components: {ProfilePicture},
    props: {
        notification: {
            type: Object as PropType<Notification>,
            required: true,
        },
    },
});
