import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57617357"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "btn btn-circle btn-md hover-dark-overlay",
  "data-bs-toggle": "dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    align: "end",
    "menu-class": "min-w-200px mt-4",
    options: _ctx.accountOptions
  }, {
    "dropdown-toggle": _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        (_ctx.me)
          ? (_openBlock(), _createBlock(_component_ProfilePicture, {
              key: 0,
              class: "square-md rounded-circle",
              user: _ctx.me
            }, null, 8, ["user"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["options"]))
}