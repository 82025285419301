import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label"
}
const _hoisted_2 = {
  key: 0,
  class: "form-control form-control-plain",
  readonly: ""
}
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Error = _resolveComponent("Error")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dropdown, { title: _ctx.dropdownValue }, {
      "dropdown-toggle": _withCtx(({title}) => [
        (_ctx.readonly)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(title), 1))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "form-control btn-outline-light w-100 text-truncate d-flex justify-content-between align-items-center",
              "data-bs-toggle": "dropdown",
              disabled: _ctx.disabled,
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dropdown-toggled')))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass({'text-muted': title === _ctx.placeholder})
              }, _toDisplayString(title), 3),
              _createVNode(_component_Fa, { icon: "chevron-down" })
            ], 8, _hoisted_3))
      ]),
      "dropdown-menu": _withCtx(({itemClasses}) => [
        _withDirectives((_openBlock(), _createBlock(_component_DropdownMenu, {
          class: "w-100",
          "emit-key": "key",
          "item-classes": itemClasses,
          options: _ctx.options,
          onOptionSelected: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('update:modelValue', $event);})
        }, {
          "dropdown-no-results": _withCtx(() => [
            _renderSlot(_ctx.$slots, "dropdown-no-results", {}, () => [
              _createElementVNode("button", {
                class: _normalizeClass(["btn dropdown-item text-truncate pointer-events-none", itemClasses]),
                "data-test-id": "no-results",
                type: "button"
              }, _toDisplayString(_ctx.$t('words.noResults')), 3)
            ])
          ]),
          _: 2
        }, 1032, ["item-classes", "options"])), [
          [_directive_infinite_scroll, {callback: _ctx.infiniteScrollCallback}]
        ])
      ]),
      _: 3
    }, 8, ["title"]),
    _createVNode(_component_Error, {
      class: "mt-4 d-block",
      "error-key": _ctx.errorKey,
      errors: _ctx.errors
    }, null, 8, ["error-key", "errors"])
  ]))
}