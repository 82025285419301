
import AccountDropdown from '@/components/users/AccountDropdown.vue';
import NotificationsDropdown from '@/components/notifications/NotificationsDropdown.vue';
import {PortalTarget} from 'portal-vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'NavbarTop',
    components: {
        AccountDropdown,
        NotificationsDropdown,
        PortalTarget,
    },
});
