
import {defineComponent, ref} from 'vue';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import authStore from '@/store/auth';
import onboardingStore from '@/store/onboarding';
import pitchStore from '@/store/pitches/index';
import {t} from '@/vendor/I18n';
import teamStore from '@/store/userTeams';
import {useRouter} from 'vue-router';

export default defineComponent({
    components: {
        Dropdown,
        ProfilePicture,
    },
    setup() {
        const {me} = authStore;
        const router = useRouter();

        const logout = (): void => {
            authStore.logout();
            teamStore.resetState();
            pitchStore.resetState();
            onboardingStore.resetState();

            router.push({
                name: 'auth.login',
            });
        };

        /*
         * Remain hidden for now until we enable step progress saving.
         * const showOnboarding = (): void => {
         *     const teamType = teamStore.currentTeam.value != null
         *         ? teamStore.currentTeam.value!.type
         *         : TeamType.TEAM;
         *
         *     const steps = teamType === TeamType.TEAM
         *         ? teamUserSteps
         *         : organisationUserSteps;
         *
         *     onboardingStore.setOnboardingSteps(steps);
         *
         *     onboardingStore.showOnboarding.value = true;
         * };
         */

        return {
            me,
            accountOptions: ref<DropdownOption[]>([
                {
                    key: 'account',
                    label: t('users.actions.account'),
                    onClick: () => router.push(me.value!.getLocation()),
                },
                /*
                 * Remain hidden for now until we enable step progress saving.
                 * {
                 *     key: 'how-pitchy-works',
                 *     label: t('users.actions.howPitchyWorks'),
                 *     onClick: showOnboarding,
                 * },
                 */
                {
                    key: 'log-out',
                    label: t('users.actions.logOut'),
                    onClick: logout,
                    variant: 'danger',
                },
            ]),
        };
    },
});
