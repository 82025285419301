
import {defineComponent} from 'vue';
import {hideModal} from '@/library/helpers';
import i18n from '@/vendor/I18n';

/**
 * Shows a Bootstrap modal component.
 * By default it shows a modal with passed title, body according to the default
 * slot and a cancel and ok button in the footer.
 * Emits 'cancel' or 'ok' when the cancel or ok button are clicked respectively.
 */
export default defineComponent({
    props: {
        cancelTitle: {
            type: String,
            default: i18n.global.t('actions.close'),
        },
        closeOnCancel: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            required: true,
        },
        noCancelBtn: {
            type: Boolean,
            default: false,
        },
        noCloseBtn: {
            type: Boolean,
            default: false,
        },
        noFooter: {
            type: Boolean,
            default: false,
        },
        okTitle: {
            type: String,
            default: i18n.global.t('actions.ok'),
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => ['sm', 'lg', 'xl'].includes(value),
        },
        title: {
            type: String,
            default: i18n.global.t('words.modal'),
        },
    },
    emits: [
        'cancel',
        'ok',
    ],
    computed: {
        modalClasses(): string[] {
            return [
                this.size ? `modal-${this.size}` : '',
            ];
        },
    },
    beforeUnmount() {
        hideModal(this.name);
    },
    methods: {
        handleCancel() {
            this.$emit('cancel');

            if (this.closeOnCancel) {
                hideModal(this.name);
            }
        },
    },
});
