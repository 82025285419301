import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header justify-content-center" }
const _hoisted_4 = {
  key: 0,
  class: "btn ms-auto",
  "data-bs-dismiss": "modal"
}
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.name,
    class: "modal fade"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog modal-dialog-centered", _ctx.modalClasses])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "modal-title", {}, () => [
            _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
            (!_ctx.noCloseBtn)
              ? (_openBlock(), _createElementBlock("button", _hoisted_4, [
                  _createVNode(_component_Fa, { icon: "cross-large" })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (!_ctx.noFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "modal-footer", {}, () => [
                _createElementVNode("div", {
                  class: _normalizeClass(["w-100", _ctx.noCancelBtn ? 'text-end' : 'd-grid gap-2 grid-col-fr-fr'])
                }, [
                  (!_ctx.noCancelBtn)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-outline-light",
                        "data-test-id": "btn-modal-cancel",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
                      }, _toDisplayString(_ctx.cancelTitle), 1))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "ok-button", { title: _ctx.okTitle }, () => [
                    _createElementVNode("button", {
                      class: "btn btn-primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('ok')))
                    }, _toDisplayString(_ctx.okTitle), 1)
                  ])
                ], 2)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 8, _hoisted_1))
}