import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190b30b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-0 align-items-start position-relative" }
const _hoisted_2 = { class: "col-auto mt-1 me-4" }
const _hoisted_3 = { class: "col whitespace-wrap" }
const _hoisted_4 = { class: "fw-bold" }
const _hoisted_5 = { class: "text-muted small" }
const _hoisted_6 = {
  key: 0,
  class: "unread-notification position-absolute end-2 rounded-circle bg-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.notification.initiator)
        ? (_openBlock(), _createBlock(_component_ProfilePicture, {
            key: 0,
            class: "square-sm rounded-circle",
            user: _ctx.notification.initiator
          }, null, 8, ["user"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`notifications.titles.${_ctx.notification.type}`)), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('notifications.body', [_ctx.notification.initiator.fullName])) + " " + _toDisplayString(_ctx.notification.subject), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('sentences.timeAgo', [_ctx.notification.createdTimeAgo])), 1)
    ]),
    (!_ctx.notification.readAt)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}