import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-6 pb-0 text-muted" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationComponent = _resolveComponent("NotificationComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
      return (_openBlock(), _createElementBlock("li", {
        key: notification.id
      }, [
        _createElementVNode("button", {
          class: "btn dropdown-item bg-transparent py-2 w-100 text-truncate",
          type: "button",
          onClick: ($event: any) => (_ctx.selectNotification(notification))
        }, [
          _createVNode(_component_NotificationComponent, {
            class: _normalizeClass(["btn-white px-2 py-4 rounded", {'bg-light': !notification.readAt}]),
            notification: notification
          }, null, 8, ["class", "notification"])
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}