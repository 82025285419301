
import {defineComponent, ref} from 'vue';
import NavbarMenu from '@/components/layouts/NavbarMenu.vue';
import NavbarTop from '@/components/layouts/NavbarTop.vue';
import TeamType from '@/library/enumerations/TeamType';
import config from '@/library/data/config';

export default defineComponent({
    name: 'Index',
    components: {
        NavbarMenu,
        NavbarTop,
    },
    props: {
        teamType: {
            type: [String, Number],
            required: true,
            validator: (value: string | number) => TeamType.values.includes(+value),
        },
    },
    setup() {
        return {
            navbarMenuCollapsed: ref<boolean>(false),
        };
    },
    computed: {
        stickyNavbar(): boolean {
            return !!this.$route.meta.stickyNavbar;
        },
        navbarBorder(): boolean {
            return !!this.$route.meta.navbarBorder;
        },
        teamTypeNumber(): number {
            return Number(this.teamType);
        },
    },
    created() {
        const collapsedValue = localStorage.getItem(config.NAVBAR_COLLAPSE_KEY);

        if (collapsedValue != null) {
            this.navbarMenuCollapsed = collapsedValue === 'true';
        } else {
            localStorage.setItem(config.NAVBAR_COLLAPSE_KEY, this.navbarMenuCollapsed ? 'true' : 'false');
        }
    },
    methods: {
        toggleCollapse() {
            this.navbarMenuCollapsed = !this.navbarMenuCollapsed;

            localStorage.setItem(config.NAVBAR_COLLAPSE_KEY, this.navbarMenuCollapsed ? 'true' : 'false');
        },
    },
});
