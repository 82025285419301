import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "list-unstyled mb-0" }
const _hoisted_2 = {
  key: 0,
  class: "d-grid"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "ms-2"
}
const _hoisted_5 = {
  key: 1,
  class: "px-4 py-3 mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_NavMenuItem = _resolveComponent("NavMenuItem")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_Collapse, _mergeProps({
        class: "d-grid",
        name: "organisation-teams"
      }, _ctx.$attrs), {
        "collapse-trigger": _withCtx(({target}) => [
          _renderSlot(_ctx.$slots, "default", {
            onClick: _ctx.fetchTeams,
            target: target
          }, () => [
            _createVNode(_component_NavMenuItem, {
              class: "collapse-trigger d-flex align-items-center collapsed mb-4 py-4",
              collapsed: _ctx.collapsed,
              "data-bs-target": target,
              "data-bs-toggle": "collapse",
              icon: "user-friends",
              title: _ctx.$t('organisations.index.teams'),
              onItemClicked: _ctx.fetchTeams
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_Fa, {
                  class: "collapse-trigger-arrow",
                  icon: "chevron-down"
                }, null, 512), [
                  [_vShow, !_ctx.collapsed]
                ])
              ]),
              _: 2
            }, 1032, ["collapsed", "data-bs-target", "title", "onItemClicked"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_WaitForResource, { resource: _ctx.teams }, {
            default: _withCtx(() => [
              (_ctx.teams.count())
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (team) => {
                      return (_openBlock(), _createBlock(_component_RouterLink, {
                        key: team.id,
                        class: "btn btn-white fw-normal text-muted text-start mb-2",
                        to: {name: 'teams.edit', params: {id: team.id}}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            class: _normalizeClass(["square-xxs rounded-2", {'mx-auto d-block': _ctx.collapsed}]),
                            src: team.logo?.thumbSm
                          }, null, 10, _hoisted_3),
                          (!_ctx.collapsed)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(team.name), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('words.noResults')), 1))
            ]),
            _: 1
          }, 8, ["resource"])
        ]),
        _: 3
      }, 16)
    ])
  ]))
}